import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Table, Row, InfoIcon } from '../components';
export const entitiesTable = [{
  param: 'Firm',
  value: 'SmartVault.Accounting.Firm'
}, {
  param: 'Client',
  value: 'SmartVault.Accounting.Client'
}, {
  param: 'Tax engagement',
  value: 'SmartVault.Accounting.TaxEngagement'
}, {
  param: 'Sales tax engagement',
  value: 'SmartVault.Accounting.SalesTaxEngagement'
}, {
  param: 'Payroll engagement',
  value: 'SmartVault.Accounting.PayrollEngagement'
}, {
  param: 'Wealth management engagement',
  value: 'SmartVault.Accounting.WealthManagementEngagement'
}, {
  param: 'Financial services engagement',
  value: 'SmartVault.Accounting.FinancialServicesEngagement'
}, {
  param: 'Investment engagement',
  value: 'SmartVault.Accounting.InvestmentEngagement'
}, {
  param: 'User association',
  value: 'SmartVault.Core.UserAssociation'
}, {
  param: 'Simple entity',
  value: 'SmartVault.Core.SimpleEntity'
}, {
  param: 'Internal documents',
  value: 'SmartVault.Core.InternalDocuments'
}, {
  param: 'Public documents',
  value: 'SmartVault.Core.PublicDocuments'
}, {
  param: 'Role association',
  value: 'SmartVault.Core.RoleAssociation'
}, {
  param: 'Engagement',
  value: 'SmartVault.Core.Engagement'
}, {
  param: 'General annual engagement',
  value: 'SmartVault.Core.GeneralAnnualEngagement'
}];
export const clientEntitiesTable = [{
  param: 'Client tax engagement',
  value: 'SmartVault.Accounting.ClientTaxEngagement'
}, {
  param: 'Client sales tax engagement',
  value: 'SmartVault.Accounting.ClientSalesTaxEngagement'
}, {
  param: 'Client payroll engagement',
  value: 'SmartVault.Accounting.ClientPayrollEngagement'
}, {
  param: 'Client wealth management engagement',
  value: 'SmartVault.Accounting.ClientWealthManagementEngagement'
}, {
  param: 'Client financial services engagement',
  value: 'SmartVault.Accounting.ClientFinancialServicesEngagement'
}, {
  param: 'Client investment engagement',
  value: 'SmartVault.Accounting.ClientInvestmentEngagement'
}, {
  param: 'Client general annual engagement',
  value: 'SmartVault.Core.ClientGeneralAnnualEngagement'
}];
export const firmEntitiesTable = [{
  param: 'Firm client',
  value: 'SmartVault.Accounting.FirmClient'
}, {
  param: 'Firm to employee',
  value: 'SmartVault.Accounting.FirmToEmployee'
}, {
  param: 'Firm to internal documents',
  value: 'SmartVault.Accounting.FirmToInternalDocuments'
}];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  entitiesTable,
  clientEntitiesTable,
  firmEntitiesTable,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "introduction"
    }}>{`Introduction`}</h1>

    <div>
	<p>
		An entity is a real world object. In SmartVault, many of these entities will be related to accounting.
		<br />
		<br />
		Entities exist only on <a href="/api/terminology#account-types" target="_blank">
			structured plans
		</a> and its hierarchy can be browsed using the path /nodes/entity for entities themselves and /nodes/entity-definition
		for the entities definition. This path allows the service to provide display and behavioral information about entities,
		primarily for client use.
		<br />
		<br />
		You can find a correspondence between the entity name and its name in the API nomenclature in the "SmartVault
		entities" table below.
	</p>
    </div>
    <br />

    <Table data="entities" title="SmartVault entities" rows={entitiesTable} mdxType="Table" />
    <br />
    <br />
    <br />
    <p>{`Entity relationships associate entities with each other.`}<br />{`
For example, an employee will have a firm to employee relationship with the firm he is a member of.`}<br /><br /></p>
    <InfoIcon mdxType="InfoIcon" />
Entity relationship example: Client
    <br />
    <br />

    <Table data="entities" title="Client relationship" rows={clientEntitiesTable} mdxType="Table" />
    <br />
    <br />
    <br />
    <InfoIcon mdxType="InfoIcon" />
Entity relationship example: Firm
    <br />
    <br />

    <Table data="entities" title="Firm relationship" rows={firmEntitiesTable} mdxType="Table" />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      